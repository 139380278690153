// Variables used in the Teller Online Style Guide (care of Josiah September 14th, 2021)

/*-------------------------------
------------ Colors -------------
---------------------------------*/
// Primary Colors
$teller-online--dark-type: #2a3b66;
$teller-online--primary-blue: #035ce0;
$teller-online--mid-blue: #70a3e0;
$teller-online--background-blue: #f0f6ff; // Not used currently - the blue background limits our client's colours as well as doesn't display well on certain screens (white on some screens)

// Neutral Colors
$teller-online--grey: #8c9093;
$teller-online--silver: #d9dbdd;
$teller-online--light-silver: #f7f8f8;

// Alert Colors
$teller-online--success-green: #6bc153;
$teller-online--failure-red: #e83434;
$teller-online--warning-yellow: #f9c441;

// Palettes generated at: https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
// palette generated from $teller-online-dark-type #2a3b66, if this value changes, it will need to be regenerated
$teller-online--dark-palette: (
 50: #e7eaf0,
 100: #c3cada,
 200: #9da9c1,
 300: #7887a7,
 400: #5b6e96,
 500: #3f5687,
 600: #394e7e,
 700: #304473,
 800: $teller-online--dark-type,
 900: #202b4d,
 contrast: (
   50: $teller-online--dark-type,
   100: $teller-online--dark-type,
   200: $teller-online--dark-type,
   300: $teller-online--dark-type,
   400: white,
   500: white,
   600: white,
   700: white,
   800: white,
   900: white
 )
);

// Palette generated from $teller-online--primary-blue #035ce0, if this value changes, it will need to be regenerated
:root {
   --primary-color--900: #1939c1;
   --primary-color: #{$teller-online--primary-blue};
   --primary-color--700: #006ef3;
   --primary-color--600: #0081ff;
   --primary-color--500: #0090ff;
   --primary-color--400: #00a0ff;
   --primary-color--300: #4db2ff;
   --primary-color--200: #87c8ff;
   --primary-color--100: #b8ddff;
   --primary-color--50: #e2f2ff;

   --primary-color--900-contrast: white;
   --primary-color-contrast: white;
   --primary-color--700-contrast: white;
   --primary-color--600-contrast: white;
   --primary-color--500-contrast: white;
   --primary-color--400-contrast: white;
   --primary-color--300-contrast: #{$teller-online--dark-type};
   --primary-color--200-contrast: #{$teller-online--dark-type};
   --primary-color--100-contrast: #{$teller-online--dark-type};
   --primary-color--50-contrast: #{$teller-online--dark-type};
}

$teller-online--primary-palette: (
   50: var(--primary-color--50),
   100: var(--primary-color--100),
   200: var(--primary-color--200),
   300: var(--primary-color--300),
   400: var(--primary-color--400),
   500: var(--primary-color--500),
   600: var(--primary-color--600),
   700: var(--primary-color--700),
   800: var(--primary-color),
   900: var(--primary-color--900),
   contrast: (50: var(--primary-color--50-contrast),
      100: var(--primary-color--100-contrast),
      200: var(--primary-color--200-contrast),
      300: var(--primary-color--300-contrast),
      400: var(--primary-color--400-contrast),
      500: var(--primary-color--500-contrast),
      600: var(--primary-color--600-contrast),
      700: var(--primary-color--700-contrast),
      800: var(--primary-color-contrast),
      900: var(--primary-color--900-contrast))
);

// Palette generated from $teller-online--failure-red #e83434, if this value changes, it will need to be regenerated
$teller-online--warn-palette: (
 50: #ffebee,
 100: #ffcdd2,
 200: #f1999a,
 300: #e77173,
 400: #f2504f,
 500: #f73e35,
 600: $teller-online--failure-red,
 700: #d6292e,
 800: #c92227,
 900: #ba121a,
 contrast: (
    50: $teller-online--dark-type,
    100: $teller-online--dark-type,
    200: $teller-online--dark-type,
    300: $teller-online--dark-type,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
 )
);

// Palette generated from $teller-online--warning-yellow #f9c441, if this value changes, it will need to be regenerated
$teller-online--caution-palette: (
 50: #fffee9,
 100: #fefac8,
 200: #fdf6a3,
 300: #fbf17f,
 400: #f9ed63,
 500: #f6e849,
 600: #fcdc4a,
 700: $teller-online--warning-yellow,
 800: #f6ae38,
 900: #f1872a,
 contrast: (
    50: $teller-online--dark-type,
    100: $teller-online--dark-type,
    200: $teller-online--dark-type,
    300: $teller-online--dark-type,
    400: $teller-online--dark-type,
    500: $teller-online--dark-type,
    600: $teller-online--dark-type,
    700: white,
    800: white,
    900: white
 )
);

// Palette generated from $teller-online--success-green #6bc153, if this value changes, it will need to be regenerated
$teller-online--success-palette: (
 50: #e9f6e6,
 100: #cbe8c3,
 200: #a9da9c,
 300: #86cc73,
 400: $teller-online--success-green,
 500: #51b630,
 600: #47a728,
 700: #39941b,
 800: #2c830e,
 900: #146400,
 contrast: (
    50: $teller-online--dark-type,
    100: $teller-online--dark-type,
    200: $teller-online--dark-type,
    300: $teller-online--dark-type,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
 )
);

// Palette generated from $teller-online--grey #8c9093, if this value changes, it will need to be regenerated
$teller-online--grey-palette: (
 50: #f4f9fc,
 100: #ecf1f4,
 200: #e2e6e9,
 300: #d1d5d8,
 400: #acb0b4,
 500: $teller-online--grey,
 600: #64686b,
 700: #515558,
 800: #333739,
 900: #141719,
 contrast: (
    50: $teller-online--dark-type,
    100: $teller-online--dark-type,
    200: $teller-online--dark-type,
    300: $teller-online--dark-type,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
 )
);

/*-------------------------------
------------- Icons -------------
---------------------------------*/
// Icon Colors
$teller-online--icon-white: white; // Used on primary coloured backgrounds
$teller-online--icon-black: black; // Whenever paired with black text
$teller-online--icon-dark: $teller-online--dark-type; // The original colour he has within the icons is #304266 but I'm not sure why it's different from dark-type so I used dark-type instead so they match
$teller-online--icon-light: $teller-online--grey;
$teller-online--icon-primary: $teller-online--primary-blue;
$teller-online--icon-success: $teller-online--success-green;
$teller-online--icon-danger: $teller-online--failure-red;
$teller-online--icon-accent: $teller-online--mid-blue;

// Icon Sizes
$teller-online--icon-x-small: 16px;
$teller-online--icon-small: 20px;
$teller-online--icon-normal: 24px;
$teller-online--icon-medium: 28px;
$teller-online--icon-large: 32px;
