@import "style-guide-variables.scss";

// Sizes of "global" Teller UI Components
$header-height: 64px;
$mobile-header-height: 56px;
$verification-banner-height: 56px;
$mobile-verification-banner-height: 56px;

$rail-width: 110px;

$skeleton-grey: map-get($teller-online--grey-palette, 100);

//Breakpoints -- all of these should be used as min-width queries
$desktop: 1240px;
$large-desktop: 1440px;
$landscape-tablet: 905px;
$portrait-tablet: 600px;
//TODO: (PROD-185)
$mobile: 599px; //TO BE REMOVED
// phone is anything smaller than portrait-tablet

@mixin layout-grid {
    // Mobile first, so no media breakpoint required
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0 16px;
    column-gap: 16px;
    box-sizing: border-box;

    @media screen and (min-width: #{$portrait-tablet}) {
        grid-template-columns: repeat(8, 1fr);
        margin: 0 32px;
    }

    @media screen and (min-width: #{$landscape-tablet}) {
        grid-template-columns: repeat(12, 1fr);
        margin: auto;
    }

    @media screen and (min-width: #{$desktop}) {
        column-gap: 24px;
    }

    @media screen and (min-width: #{$large-desktop}) {
        // no additional styles needed, desktop will suffice
    }

    @include layout-grid-width;
}

@mixin layout-grid-width {
    width: calc(100% - 32px);

    @media screen and (min-width: #{$portrait-tablet}) {
        width: calc(100% - 64px);
    }

    @media screen and (min-width: #{$landscape-tablet}) {
        max-width: 840px;
    }

    @media screen and (min-width: #{$desktop}) {
        width: 1080px;
        max-width: calc(100% - 32px); //16px margin either side
    }

    @media screen and (min-width: #{$large-desktop}) {
        // no additional styles needed, desktop will suffice
    }

    @at-root .main-layout--cart :not(.welcome-view) .layout-grid {
        @media screen and (min-width: #{$desktop}) {
            width: 100%;
        }

        @media screen and (min-width: #{$large-desktop}) {
            max-width: calc(100% - 64px); //32px margin either side
        }
    }
}

//TODO: Once css subgrids get better support the current nested grids should be updated https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Grid_Layout/Subgrid
@mixin remove-layout-grid-margins($width: auto, $strong-override: false) {
    // remove the margin and width that are applied due to layout-grid
    // this expects the margins to be handled by a parent grid
    margin: auto;
    width: $width;
    max-width: none;

    @if $strong-override {
        @media screen and (min-width:#{$portrait-tablet}) {
            // remove the margin and width that are applied due to layout-grid
            // this expects the margins to be handled by a parent grid
            margin: auto;
            width: $width;
            max-width: none;
        }

        @media screen and (min-width:#{$desktop}) {
            // remove the margin and width that are applied due to layout-grid
            // this expects the margins to be handled by a parent grid
            margin: auto;
            width: $width;
            max-width: none;
        }

        @media screen and (min-width:#{$large-desktop}) {
            // remove the margin and width that are applied due to layout-grid
            // this expects the margins to be handled by a parent grid
            margin: auto;
            width: $width;
            max-width: none;
        }
    }
}

// only for use on pages with "narrow-page" as a class

@mixin narrow-layout-grid {
    grid-column-start: 1;
    grid-column-end: -1;

    @media screen and (min-width: #{$landscape-tablet}) {
        grid-column: 3 / span 8;
    }

    @media screen and (min-width: #{$desktop}) {
        grid-column: 4 / span 6;
    }
}

@mixin narrow-button-layout-grid {
    grid-column-start: 1;
    grid-column-end: -1;

    @media screen and (min-width: #{$portrait-tablet}) {
        grid-column-end: span 2;
    }

    @media screen and (min-width: #{$landscape-tablet}) {
        grid-column-start: 3;
    }

    @media screen and (min-width: #{$desktop}) {
        grid-column-start: 4;
    }
}


@mixin payment-integration-layout-grid() {
    .payment-method-details, .payment-method-details.layout-grid {
        margin-bottom: 16px;

        + h2 {
            margin-bottom: 0;
        }
    }
    .layout-grid {
        position: relative;
        @include remove-layout-grid-margins;

        .mat-form-field, .checkbox {
            grid-column: 1 / -1;

            &.card-holder-name {
                @media screen and (min-width:#{$desktop}) {
                    grid-column-end: span 6;
                }
            }

            &:not(.card-holder-name) {
                @media screen and (min-width:#{$landscape-tablet}) {
                    grid-column-end: span 6;

                    &:nth-child(even) {
                        grid-column-start: 7;
                    }
                }
            }
        }

        .payment-method-details__card-info, .payment-method-details__bank-info {
            grid-column: 1 / -1;

            @include layout-grid;

            @include remove-layout-grid-margins(100%, true);

            .mat-form-field {
                grid-column: 1 / -1;
            }
        }

        .payment-method-details__card-info {
            // this allows for the spacing required
            @media screen and (min-width:#{$desktop}) {
                @at-root .payment-method--anonymous & {
                    min-height: 106px;
                }
            }

            .mat-form-field {
                &:first-child {
                    @media screen and (min-width:#{$landscape-tablet}) {
                        grid-column-end: span 6;
                    }
                    @media screen and (min-width:#{$desktop}) {
                        grid-column-end: span 3;
                    }
                }

                &:nth-child(2) {
                    @media screen and (min-width:#{$landscape-tablet}) {
                        grid-column: 7 / span 4;
                    }
                    @media screen and (min-width:#{$desktop}) {
                        grid-column: 4 / span 2;
                    }
                }

                &:nth-child(3) {
                    min-width: 0; //ensure it won't extend past 1 column

                    @media screen and (min-width:#{$landscape-tablet}) {
                        grid-column: 11 / span 2;
                    }
                    @media screen and (min-width:#{$desktop}) {
                        grid-column: 6 / span 1;
                    }
                }
            }
        }

        .payment-method-details__bank-info {
            .mat-form-field {
                @media screen and (min-width:#{$desktop}) {
                    grid-column-end: span 6;
                }

                &.routing-number, &.account-number {
                    @media screen and (min-width:#{$landscape-tablet}) {
                        grid-column-end: span 6;
                    }
                    @media screen and (min-width:#{$desktop}) {
                        grid-column-end: span 3;
                    }
                }

                &.account-number {
                    @media screen and (min-width:#{$landscape-tablet}) {
                        grid-column-start: 7;
                    }
                    @media screen and (min-width:#{$desktop}) {
                        grid-column-start: 4;
                    }
                }

                .mat-icon {
                    width: 16px;
                    height: 16px;
                    position: relative;
                    top: 3px;

                    &.mat-prefix {
                        left: -3px
                    }
                    &.mat-suffix {
                        right: -3px;
                    }
                }
            }
        }

        .cvv-instructions, .echeck-instructions {
            grid-column: 1 / -1;

            width: 100%;
            max-width: 400px;
            display: flex;
            flex-direction: column;

            // Required to prevent image stretching in safari for macOS Monterey
            img {
                width: 100%;
            }

            @media screen and (min-width:#{$desktop})
            {
                grid-column-start: 7;

                position: absolute;
            }

            &--short {
                max-width: 354px;
            }
        }

        .remember-payment-method {
            grid-column: 1 / -1;

            margin-top: 16px;

            @media screen and (min-width:#{$desktop})
            {
                margin-top: 8px;
            }
        }
    }
    .payment-method-details ~ re-captcha {
        display: flex;
        margin-bottom: 12px;
    }
}

@mixin rail-layout-grid {
    display: grid;
    box-sizing: border-box;
    // not designed for mobile or portrait tablet so no additional styles

    @media screen and (min-width: #{$landscape-tablet}) {
        grid-template-columns: repeat(12, 1fr);
        column-gap: 16px;
        margin: 0 16px;
    }

    @media screen and (min-width: #{$desktop}) {
        column-gap: 24px;
    }

    @media screen and (min-width: #{$large-desktop}) {
        margin: 0 32px;
        column-gap: 16px;
    }

    @include rail-layout-grid-width;
}

@mixin rail-layout-grid-width {
    @media screen and (min-width: #{$landscape-tablet}) {
        width: calc(100% - 32px); //16px * 2
    }

    @media screen and (min-width: #{$large-desktop}) {
        width: calc(100% - 64px); //32px * 2
    }
}

@mixin site-background {
    background: #ecf0f4;
    display: flex;
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    padding:0;
    margin:0;
}

@mixin skeleton-block {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 36px;
    width: 100px;
    padding: 0 16px;
    border-radius: 4px;
    background-color: $skeleton-grey;
}

:root {
    --banner-bg: #{map-get($teller-online--dark-palette, 800)};
    --banner-fg: #{map-get(map-get($teller-online--dark-palette, contrast), 800)};
    --banner-link: #{map-get($teller-online--primary-palette, 300)};
    --banner-link-hover: #{map-get($teller-online--primary-palette, 200)};
}