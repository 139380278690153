// Teller Online Style Guide (care of Josiah September 14th, 2021)
@import "./style-guide-variables.scss";

/*-------------------------------
------------- Icons -------------
---------------------------------*/
// Icon Base Styles
.teller-online-icon {
    display: inline-block;
    vertical-align: middle;
    height: $teller-online--icon-normal;
    width: $teller-online--icon-normal;

    // The header icons (.teller-online-icon--header) are dark by default (handled in the default-styles.css file)
    &.teller-online-icon--dark {
        // Displays as colored in
        .filled {
            fill: $teller-online--icon-dark;
        }
        // Displays as a cut out/white as appropriate
        .cutout {
            fill: none;
        }
    }

    &.teller-online-icon--light {
        // Displays as colored in
        .filled {
            fill: $teller-online--icon-light;
        }
        // Displays as a cut out/white as appropriate
        .cutout {
            fill: none;
        }
    }

    &.teller-online-icon--primary {
        // Displays as colored in
        .filled {
            fill: $teller-online--icon-primary;
        }
        // Displays as a cut out/white as appropriate
        .cutout {
            fill: none;
        }
    }

    // Default to on a dark background
    &.teller-online-icon--white {
        // Displays as colored in
        .filled {
            fill: $teller-online--icon-white;
        }
        // Displays as a cut out/white as appropriate
        .cutout {
            fill: none;
        }
    }

    // Default to on a dark background
    &.teller-online-icon--black {
        // Displays as colored in
        .filled {
            fill: $teller-online--icon-black;
        }
        // Displays as a cut out/white as appropriate
        .cutout {
            fill: none;
        }
    }
    
    &.teller-online-icon--accent {
        // Displays as colored in
        .filled {
            fill: $teller-online--icon-accent;
        }
        // Displays as a cut out/white as appropriate
        .cutout {
            fill: none;
        }
    }

    &.teller-online-icon--success {
        // Displays as colored in
        .filled {
            fill: $teller-online--icon-success;
        }
        // Displays as a cut out/white as appropriate
        .cutout {
            fill: $teller-online--icon-white;
        }
    }

    &.teller-online-icon--danger, &.teller-online-icon--warn {
        // Displays as colored in
        .filled {
            fill: $teller-online--icon-danger;
        }
        // Displays as a cut out/white as appropriate
        .cutout {
            fill: $teller-online--icon-white;
        }
    }

    &.teller-online-icon--caution {
        .filled {
            fill: map-get($teller-online--caution-palette, 900);
        }

        .cutout {
            fill: map-get(map-get($teller-online--caution-palette, contrast), 900);
        }
    }
}