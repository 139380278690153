// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@include mat.legacy-core();

@import "./default-variables";
@import "style-guide";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$public-portal-primary: mat.define-palette($teller-online--dark-palette, 800);
$public-portal-accent: mat.define-palette($teller-online--primary-palette, 800);

// The warn palette is optional (defaults to red).
$public-portal-warn: mat.define-palette($teller-online--warn-palette, 600);

//Typography needs to be explicitly set as of Angular 15
$main-typography: mat.define-legacy-typography-config();

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$public-portal-theme: mat.define-light-theme((
    color: (
        primary: $public-portal-primary,
        accent: $public-portal-accent,
        warn: $public-portal-warn,
    ),
    typography: $main-typography
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($public-portal-theme);
@include mat.all-legacy-component-themes($public-portal-theme);
@include mat.all-component-typographies($public-portal-theme);

.mat-fab {
    position: fixed !important;
    bottom: 16px;
    right: 16px;
}

// in the version of angular material we have, extended doesn't exist for some reason
.mat-fab, .mat-mini-fab {
    &.mat-fab-extended {
        width: auto;
        border-radius: 32px;
        padding-left: 20px;
        padding-right: 20px;

        .mat-icon {
            margin-right: 8px;
        }
    }
}

.mat-flat-button, .mat-icon-button, .mat-fab, .mat-mini-fab {
    &:not(.mat-button-disabled).mat-accent {
        &:hover, &:active, &:focus {
            color: map-get(map-get($teller-online--primary-palette, contrast), 900);
            background-color: map-get($teller-online--primary-palette, 900);
        }

        .teller-online-icon {
            .filled {
                fill: map-get(map-get($teller-online--primary-palette, contrast), 900);
            }
            .cutout {
                fill: map-get($teller-online--primary-palette, 900);
            }
        }
    }

    &:not(.mat-button-disabled).mat-warn {
        &:hover, &:active, &:focus {
            color: map-get(map-get($teller-online--warn-palette, contrast), 800);
            background-color: map-get($teller-online--warn-palette, 800);
        }

        .teller-online-icon {
            .filled {
                fill: map-get(map-get($teller-online--warn-palette, contrast), 800);
            }
            .cutout {
                fill: map-get($teller-online--warn-palette, 800);
            }
        }
    }
}

.mat-button:not(.mat-button-disabled).mat-accent {
    .teller-online-icon {
        .filled {
            fill: map-get($teller-online--primary-palette, 800);
        }
        .cutout {
            fill: map-get(map-get($teller-online--primary-palette, contrast), 800);
        }
    }

    &:hover, &:active, &:focus {
        color: map-get($teller-online--primary-palette, 900);
        background-color: rgba(map-get($teller-online--primary-palette, 50), 0.5);

        .teller-online-icon {
            .filled {
                fill: map-get($teller-online--primary-palette, 900);
            }
            .cutout {
                fill: map-get(map-get($teller-online--primary-palette, contrast), 900);
            }
        }
    }
}

.mat-button:not(.mat-button-disabled).mat-warn {
    .teller-online-icon {
        .filled {
            fill: map-get($teller-online--warn-palette, 600);
        }
        .cutout {
            fill: map-get(map-get($teller-online--warn-palette, contrast), 600);
        }
    }

    &:hover, &:active, &:focus {
        color: map-get($teller-online--warn-palette, 800);
        background-color: rgba(map-get($teller-online--warn-palette, 50), 0.5);

        .teller-online-icon {
            .filled {
                fill: map-get($teller-online--warn-palette, 800);
            }
            .cutout {
                fill: map-get(map-get($teller-online--warn-palette, contrast), 800);
            }
        }
    }
}

.mat-icon-button {
    .mat-button-wrapper {
        display: block;
    }
}

.mat-button, .mat-flat-button {
    .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .mat-icon {
        &:first-child {
            margin-right: 8px;
        }
        &:last-child {
            margin-left: 8px;
        }
    }
    i:first-child + .mat-icon {
        margin-right: 8px;
    }
}

.mat-snack-bar-container {
    background-color: map-get(map-get($teller-online--dark-palette, contrast), 800);
    color: map-get($teller-online--dark-palette, 800);
    font-size: 16px;
    position: relative;
    margin-top: calc($mobile-header-height + 10px) !important;

    @media screen and (min-width: #{$portrait-tablet}) {
        margin-top: calc($header-height + 6px) !important;
    }
}

@supports selector(.cdk-overlay-pane:has(.mat-snack-bar-container)) {
    .mat-snack-bar-container {
        margin-top: 0 !important;
    }
    .cdk-overlay-pane:has(.mat-snack-bar-container){
        margin-top: calc($mobile-header-height + 10px) !important;

        @media screen and (min-width: #{$portrait-tablet}) {
            margin-top: calc($header-height + 6px) !important;
        }
    }
}


.mat-table {
    tr {
        padding: 0 16px;
        border-bottom: 1px solid map-get($teller-online--grey-palette, 200);

        td, th {
            border-bottom: 0;
        }
    }

    tr.table-details-expandable {
        height: 0;
        padding: 0;

        td {
            padding: 0;
        }

        &.expanded {
            td {
                border-bottom: 1px solid map-get($teller-online--grey-palette, 200);
            }
        }

        .table-details-row {
            overflow: hidden;
            min-height: 48px;

            &__content {
                // remove the margin and width that are applied due to layout-grid
                // these will be handled by the table (parent)
                margin: auto;
                width: auto;
                max-width: none;

                padding: 16px 16px 8px;
            }

            .mat-spinner {
                margin: 0 auto;
            }
        }
    }
}

.mat-list .mat-subheader {
    font-weight: bold;
}


.mat-drawer-container {
    background: transparent !important; // we want to see our background color, not this background color
    z-index: unset !important;
}

.mat-card {
    // for better accessibility
    border: 1px solid map-get($teller-online--grey-palette, 300);

    .mat-card-title {
        line-height: 1.2;
    }
}

.mat-card.actionable:hover {
    background: map-get($teller-online--primary-palette, 50);
    cursor: pointer;
}

// for some reason they only remove the margin on the first child so I'm also removing it on the last one
.mat-card-actions:not(.mat-card-actions-align-end) .mat-button:last-child,
.mat-card-actions:not(.mat-card-actions-align-end) .mat-raised-button:last-child,
.mat-card-actions:not(.mat-card-actions-align-end) .mat-stroked-button:last-child {
    margin-right: 0;
    margin-left: 0;
}

.mat-form-field--suffix, .mat-form-field--prefix {
    .mat-form-field-flex {
        align-items: flex-end;

        .mat-icon {
            margin-bottom: 4px;
        }
    }
}

// copy the disabled styles
.mat-form-field-appearance-standard.mat-form-field-readonly {
    // override so this underline does not appear
    .mat-form-field-flex:hover~.mat-form-field-underline .mat-form-field-ripple,
    &.mat-focused .mat-form-field-ripple,
    &.mat-form-field-invalid .mat-form-field-ripple {
        opacity: 0 !important;
    }
    .mat-form-field-underline {
        background-position: 0;
        background-color: transparent;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
        background-size: 4px 100%;
        background-repeat: repeat-x;
    }
    .mat-input {
        color: map-get($teller-online--grey-palette, 500);
    }
}

.mat-flat-button.mat-button-disabled {
    .mat-icon.teller-online-icon {
        .filled {
            fill: map-get($teller-online--grey-palette, 500);
        }
    }
}


// Much needed styles to emulate an invalid state for a mat-radio-group or a group of checkboxes within a form
// in a way that matches mat-form-field-invalid
.mat-form-group-invalid {
    color: map-get($teller-online--warn-palette, 800);

    label {
        color: map-get($teller-online--warn-palette, 800);
    }

    .mat-checkbox-frame,
    .mat-radio-outer-circle {
        border-color: map-get($teller-online--warn-palette, 800);
    }
}

.mat-button.mat-accent {
    color: var(--primary-color--900);
}